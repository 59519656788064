import "./App.css";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import Services from "./components/Services";
import Home from "./Pages/Home";
import { Box } from "@mui/material";
import undermaintainance from "./assets/undermaintainance.jpg";

function App() {
  return (
    <div
      className=""
      style={{ width: "100%", height: "100vh", objectFit: "cover" }}>
      <img
        src={undermaintainance}
        width="100%"
        height="100%"
        alt="maintainance"
      />
      {/* <main>
        <Navigation />
        <Box component="main">
          <Home />
        </Box>
        <Services />
        <AboutUs />
        <Contact />
        <Footer />
      </main> */}
    </div>
  );
}

export default App;
