import { createTheme } from "@mui/material";

const Theme = createTheme({
  typography: {
    // fontFamily: "Sora, sans-serif",
    // allVariants: {
    //   color: "#ffffff",
    // },
  },
  palette: {
    background: {
      default: "#0f0715",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#ffff", // Set the default icon color to white
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "black", // Set your desired background color
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // This will make the text case normal
        },
      },
    },
  },
});

export default Theme;
